<template>
  <div class="everiting_box_body" :class="isRTLPage ? 'right-align' : 'left-align'">
    <b-img-lazy
      v-bind="mainProps"
      :src="imageUrl"
      alt="Not Found"
      onerror="this.src='assets/img/lesiurpackages_hotelsimg4.jpg'"
    ></b-img-lazy>
    <div class="contain_body">
      <div class="card-title text-right">
        <h6>
          <div class="title-letter">{{ item.hotelName }}, {{ item.extendedProperties.destination.name}}</div>
        </h6>
      </div>
      <!-- <div class="btn btn-primary category-text-button">{{ getDealCategory(item.dealCategoryIds) }}</div> -->
      <p class="first-paragraph">
        <span class="icon"><i class="far fa-calendar-alt"></i></span>
        {{ $t("search-result.domestic-search.check-in") }} :
        {{ changeDateFormat(item.checkinDate) }}
      </p>
      <p>
        <span class="icon"><i class="far fa-calendar-alt"></i></span>
        {{ $t("search-result.domestic-search.check-out") }} :
        {{ changeDateFormat(item.checkoutDate) }}
      </p>
      <!-- <p>
        <span class="icon"><i class="fas fa-bus-alt"></i></span>
        {{ $t("home.slide-deal.transfers") }} : {{ item.transfers ? item.transfers : "" }}
      </p> -->
      <p>
        <span class="icon"><i class="fas fa-utensils"></i></span>
        {{ $t("home.slide-deal.hosting-base") }} :
        {{ item.basisName && item.basisName !== 'null' ? item.basisName : $t("basis-name." + item.basisCode.toLowerCase().trim())}}
      </p>
      <p>
        <span class="icon"><i class="fas fa-sort-numeric-up"></i></span>
        {{ $t("home.total-night") }} :
        {{ getDuration(item.checkinDate, item.checkoutDate) }}
      </p>
      <div class="price pt-2">
        <div class="d-flex align-items-center" :class="isOdysseySite && !isFCLAgent && 'justify-content-around'">
          <div>
            <span style="font-size: 1.3rem;">₪<span style="font-size: 2rem;">{{ discountedPrice }}</span></span>
            <span style="font-size: 1rem;" v-if="isOdysseySite && !isFCLAgent ">{{$t("home.this-order-has")}} <span>{{`${commission}%`}}</span></span>
            <p class="p-0">
              <span class="price-remark" style="font-size: 0.8em;">{{ item.priceRemark ? item.priceRemark : "" }}</span>
            </p>
          </div>
        </div>
        <p class="p-0 price-remark">
          <!-- {{ $t("product-page.remarks") }} : -->
          <span class="price-remark" style="font-size: 0.8em;">{{ remark }}</span>
        </p>
      </div>
      <!-- <a :href="item.productPageUrl"> -->
      <a :href="productPageLink" v-if="isLanding" class="mt-3">
        <div class="order-button btn-primary">
          {{ $t("home.slide-deal.order-button") }}
          <span class="icon" style="vertical-align: text-top;"><i class="fas fa-angle-double-left"></i></span>
        </div>
      </a>
      <router-link :to="productPageLink" v-else class="mt-3">
        <div class="order-button btn-primary">
          {{ $t("home.slide-deal.order-button") }}
          <span class="icon" style="vertical-align: text-top;"><i class="fas fa-angle-double-left"></i></span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BImgLazy } from 'bootstrap-vue';
import dayjs from 'dayjs';
// import dealProductLinkMixin from '@/mixins/dealProductLink';

export default {
  name: 'LesiurPackageItemTheme0',
  props: ['item'],
  components: {
    BImgLazy,
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
      dealType: 'GET_DEAL_TYPE',
      device: 'GET_DEVICE',
      isMemberLogin: 'IS_AUTHORIZED',
      memberDiscountPercent: 'GET_DISCOUNT_PERCENT',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      marketerId: 'GET_MARKETER_ID',
      isRTLPage: 'GET_IS_RLT_PAGE',
    }),
    imageUrl() {
      const { item } = this;
      let url = '';

      if (!item) return '';
      url = (item.imageUrl !== 'null') ? url = item.imageUrl : null;
      url = url || item.extendedProperties.hotelInfo.mainImageUrls[0] || item.extendedProperties.hotelConversion.imageUrl || 'assets/img/lesiurpackages_hotelsimg5.jpg';
      return url;
    },
    discountedPrice() {
      const { totalAfterDiscount, totalPrice } = this.item;
      if (this.isOdysseySite && !this.isFCLAgent) return Math.round(totalPrice);
      if (!this.isMemberLogin) return Math.round(totalAfterDiscount);
      const isAtlantis = this.item?.extendedProperties?.sourceName === 'ATLANTIS';
      const isCreditCardRemarkHotel = false;
      const { memberDiscountPercent } = this;
      const price = (isAtlantis || isCreditCardRemarkHotel) ? totalAfterDiscount : totalAfterDiscount * (1 - memberDiscountPercent / 100);
      return Math.round(price);
    },
    commission() {
      const { isFCLAgent, item } = this;
      const { extendedProperties } = item;
      if (extendedProperties.sourceName === 'ATLANTIS') return 5;
      let { commission } = extendedProperties.hotelConversion;
      if (!isFCLAgent) {
        commission = Number(commission) - 5;
      }
      return commission;
    },
    productPageLink() {
      const { marketerId, item, imageUrl } = this;
      let url = `${item.productPageUrl}&dealId=${item.id}&mainimg=${imageUrl}`;
      if (marketerId && marketerId !== '') url = `${url}&marketerId=${marketerId}`;
      return url;
    },
    remark() {
      return this.supplierName === 'ATLANTIS' ? this.item?.roomRemark?.trim() || '' : '';
    },
    supplierName() {
      const { item } = this;
      const { extendedProperties } = item;
      return extendedProperties.sourceName;
    },
  },
  // mixins: [dealProductLinkMixin],
  data() {
    return {
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        class: 'card-img-top img-fluid',
      },
      host: window.location.host,
    };
  },
  mounted() {
  },
  methods: {
    changeDateFormat(str) {
      return str.split('T')[0].split('-').reverse().join('/');
    },
    getDealCategory(ids) {
      return this.dealType.filter((item) => ids.indexOf(item.id) > -1).map((item) => (item.name)).join(', ');
    },
    getDuration(checkIn, checkOut) {
      return dayjs(checkOut).diff(dayjs(checkIn), 'day');
    },
  },
};
</script>
<style scoped>
.everiting_box .everiting_box_body .contain_body .price p.price-remark{
  line-height: 14px;
}
.btn.category-text-button{
  margin: 110px auto 0 auto;
  background: #005ca3;
  border: 1px solid rgb(255 255 255 / 0.5);
  border-radius: 30px;
  width: fit-content;
  text-transform: capitalize;
  padding: 5px 30px;
}
.everiting_box_body{
  direction: rtl;
}
.everiting_box_body .contain_body .card-title .title-letter{
  padding-left: 10%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  direction: rtl;
  font-size: 1.5rem;
}
.everiting_box_body:hover .contain_body .card-title .title-letter{
  padding-left: 0;
  overflow: inherit;
  max-height: inherit;
}

.everiting_box_body .contain_body .card-title {
  position: absolute;
  z-index: 1;
  margin: 0;
  padding: 10px 0;
  top: 20px;
  right: 5px;
  width: 95%;
  color: white;
  transition: 0.5s;
}
.everiting_box_body:hover .card-title {
  width: 105%;
}
.everiting_box_body .card-title:after {
  content: "";
  height: 100%;
  width: 110%;
  position: absolute;
  display: block;
  top: 0;
  right: -20%;
  transform: skew(25deg, 0deg);
  z-index: -1;
  background-color: #00adef;
  box-shadow: #023b50 2px 10px 15px;
  height: 50px;
}
.everiting_box_body .ui.rating {
  height: auto;
  color: #025575;
  background: transparent;
  border: none;
  direction: rtl;
  padding: 0;
}
.everiting_box .everiting_box_body .price {
  font-size: 28px;
}
</style>
<style>
.everiting_box_body .ui.rating span.b-rating-star {
  display: contents;
}
.everiting_box .everiting_box_body .price {
  display: block;
  direction: rtl;
}
.everiting_box .everiting_box_body .price .price-remark {
  font-size: initial;
  font-weight: initial;
}
.everiting_box .everiting_box_body .order-button:hover {
  transform: scale(1.05);
}
.everiting_box .everiting_box_body .order-button {
  transition-duration: 0.1s;
  content: "";
  background: #005ca3;
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  margin: 0 auto;
  padding: 5px;
  width: 100px;
  text-align: center;
  text-transform: capitalize;
  border-radius: 30px;
  z-index: 91;
  border: 1px solid rgb(255 255 255 / 0.5);
}
.everiting_box_body.left-align .contain_body {
  direction: ltr;
  text-align: left;
}
.everiting_box_body.left-align .title-letter,
.everiting_box_body.left-align .price div {
  direction: ltr;
}
.deal-container .everiting_box .everiting_box_body .contain_body p{
  font-size: 1.2rem;
}
.deal-container .everiting_box .everiting_box_body .price {
  font-size:  1.5rem;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .everiting_box_body .ui.rating span.b-rating-star {
    display: inline-table;
  }
}
</style>
